import { graphql, Link } from "gatsby"
import React from "react"
import { Layout } from "../components/Layout/Layout"
import { Paths } from "../data"

interface CampaignPostProps {
  data: {
    cms: {
      campaign: {
        fields: {
          content: string
          author: string
          date: string
        }
        title: string
      }
    }
  }
}

const CampaignPostTemplate: React.FC<CampaignPostProps> = ({ data }) => {
  const {
    title,
    fields: { author, date, content },
  } = data.cms.campaign

  // const handleClick = useCallback(() => {
  //   console.log("comment sent")
  // }, [])

  return (
    <Layout secondLayout flex bordertop="border-t-2" background="bg-white">
      <div className="flex flex-grow">
        <div className="flex flex-col pt-16 pb-6 lg:w-9/12">
          <h2 className="mb-2 text-4xl text-headings opacity-70">{title}</h2>
          <div className="mb-14 space-x-1 font-titilliumWeb text-headings opacity-60">
            <span>von {author}</span>
            <span>|</span>
            <span>{date}</span>
            <span>|</span>
            <Link to={Paths.Campaigns}>Aktionen</Link>
            {/* <span>|</span>
            <span>0 Kommentare</span> */}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="rich-text-3 mb-16"
            style={{ fontSize: 13 }}
          />
          {/* <div className="flex flex-col">
            <h3 className="text-2xl tracking-wider opacity-60 mb-12">
              Kommentar absenden
            </h3>
            <textarea
              name="comments"
              rows={6}
              placeholder="Kommentar"
              className="lg:mr-6 mb-6 lg:mb-10 p-2 fontTitilliumWebOrPlayFair-titilliumWeb text-lg bg-comments-textare placeholder-headings placeholder-opacity-50 focus:outline-none"
            />
            <button
              onClick={handleClick}
              className={`self-end lg:mr-6 tracking-wider flex items-center fontTitilliumWebOrPlayFair-navigation text-captchaSendButton text-2xl border-2 border-captchaSendButton hover:bg-glossy-200 hover:border-transparent rounded-md px-4 py-2 ml-4 duration-300 ease-in-out focus:outline-none`}
              style={{
                transitionProperty: "width, background-color, border-color",
              }}
            >
              Kommentar senden
            </button>
          </div> */}
        </div>
        <div className="ml-10 hidden border-r border-gray-200 lg:block" />
      </div>
    </Layout>
  )
}

export default CampaignPostTemplate

export const pageQuery = graphql`
  query CampaignPostBySlug($slug: ID!) {
    cms {
      campaign(id: $slug, idType: SLUG) {
        fields {
          content
          author
          date
        }
        title
      }
    }
  }
`
